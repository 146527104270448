import { StaticImage } from "gatsby-plugin-image";
import React from "react";

import Button from "../../../DesignSystem/Button";
import Hero from "../../../components/Hero";
import HeroCard from "../../../components/HeroCard";
import { Container } from "../../../styles/UI";

import chartIcon from "../../../images/home/chart.png";
import costIcon from "../../../images/home/cost.png";
import cronoIcon from "../../../images/home/crono.png";


import logoBgNegative from "../../../images/home/logo-negative.svg";

import { navigate } from "gatsby-link";

import { Link } from "gatsby";

export default function HeroHome() {
  const handleClick = () => {
    navigate("/produtos");
  };

  return (
    <>
      <Hero>
        <div className="cta">
          <h1>Transforme o departamento fiscal da sua empresa com a Taxcel</h1>
          <p>
          Otimize processos, reduza riscos e aumente a eficiência da sua equipe com soluções inteligentes de compliance fiscal e analytics. Tenha mais controle sobre suas obrigações tributárias e foque no que realmente importa para o seu negócio.
          </p>
          <Button onClick={handleClick} bg="secondary" color="primary">
            Conheça nossas soluções
          </Button>
        </div>
        <div className="illustration">
          <StaticImage
            src="../../../images/hero-men-nofilter.png"
            quality={100}
            className="hero-illustration"
            layout="fullWidth"
            alt="imagem de um homem sorrindo"
          />
        </div>
      </Hero>
      <Container>
        <HeroCard>
          <Link to="/taxsheets">
            <img src={cronoIcon} alt="" />
            <p>
              Otimize processos e <br />
              economize tempo
            </p>
          </Link>
          <Link to="/taxsheets">
            <img src={costIcon} alt="" />
            <p>
              Reduza erros e <br />
              custos operacionais
            </p>
          </Link>
          <Link to="/taxdashs">
            <img src={chartIcon} alt="" />
            <p>
              Utilize os dados para <br />
              decisões data-driven
            </p>
          </Link>
        </HeroCard>
      </Container>
    </>
  );
}
